import React from "react"

const PaintingEN = () => <>
  <h2>Painting</h2>
  <p className="bigger-text text-center">
    We offer modern systems impose anti-corrosion protective coatings systems: from light industrial
    systems (thin layers of paint) for machinery and steel, used in mild conditions, to high-performance
    systems (thick layer of paint) , used in chemically and environmentally harsh conditions. Our
    protective coating services are carried out using a wide range of high quality anti-corrosive paints
    and lacquers, produced by dozens of manufacturers from around the World, widely available in the
    European market.</p><p>The work performed in our paint shop includes:</p>
  <ul>
    <li>Application of single and multi-layer anti-corrosive systems.</li>
    <li>Application of fire retardant systems on steel structures type R15, R30, R60, R120 (we have
      certifications for the design, supervision and applying fire-resistant coatings from paint
      manufacturers such as: International Coatings, PPG Coatings, Carboline, Charflame, Sika, and
      others).
    </li>
    <li>Substrate preparation and application of protective coatings systems in accordance with our
      Quality Control System and compatible with the national standards: PN-EN ISO 1090-2, PN-
      EN ISO 8501-1, PN-EN ISO 12944-5: 2009.
    </li>
    <li>Application of specialized protective coatings:
      <ul>
        <li>Heat-resistant - the ability to apply paints on substrates with temperatures of up to 150° C
          or 260 ° C, thermal resistance without discoloration of the coating of up to 650° C.
        </li>
        <li>Chemically resistant - coatings resistant to aggressive chemical compounds, petroleum
          products, radiation etc. These systems are used to protect building and supporting
          structures in chemical plants and interior reactors, tanks (systems with a guarantee for
          demineralized water tanks) and other devices.
        </li>
        <li>Insulating - thermal resistance without discoloration of the coating up to 180 ° C meets the
          requirements of the1 safety standard PN-ISO 13732- (safe to touch 55 ° C, when the
          construction has a temperature of up to180 ° C)
        </li>
      </ul></li>
  </ul>
  <p className="text-center"> During application of the anti-corrosion protection systems, we perform measurements and
    monitoring of the environmental parameters, substrate preparation and application with the use of
    our own measuring equipment in accordance with national standards PN-EN ISO1090-2, PN-EN
    ISO8501-1, PN-EN ISO12944-5: 2009 or by other standards, if agreed with the client.</p>

</>

export default PaintingEN