import React from "react"

const PaintingDE = () => <>
  <h2>Lackierung</h2>
  <p className="bigger-text text-center">In unseren Lackierhallen lackieren wir alle Arten von Stahlkonstruktionen,
    sowie Bauelemente von Maschinen für unterschiedliche Industriezweige. Wir bieten die Antikorrosionsbeschichtung
    mithilfe von Komponenten der marktführenden Hersteller aus ganz Europa. Selbstverständlich werden sämtliche
    Leistungen unter Einhaltung der geltenden Normen und Rechtsvorschriften erbracht</p><p>Unser Fachpersonal verfügt
  selbstverständlich über die notwendige Qualifizierung. Zu unserem Team gehören neben den erfahrenen Lackierern und
  Produktionshelfern auch die hochqualifizierten Qualitätsprüfer, Fachingenieure für Stahlkonstruktionen,
  Bauingenieurwesen, Maschinenbau und Produktionstechnik</p><p>In unseren Lackierhallen werden folgende Arbeiten
  ausgeführt:</p>
  <ul>
    <li>Auftragen von ein- und mehrschichtigen Antikorrosionsbeschichtungssystemen</li>
    <li>Auftragen von Brandschutzbeschichtungssystemen für Stahlkonstruktionen mit der Feuerwiderstandsklasse R15,
      R30, R60, R120. Wir besitzen Zertifikate für das komplette Verfahren u.a. von International Coatings, PPG
      Coatings, Carboline, Charflame, Sika
    </li>
    <li>Untergrundvorbehandlung und Beschichtung gemäß Vorgaben PN-EN ISO 1090-2, PN-EN ISO 8501-1, PN-EN ISO
      12944-5:2009,
    </li>
    <li>Auftragen von speziellen Schutzbeschichtungen <ul>
      <li>Hitzebeständigkeit – es besteht bei uns die Möglichkeit, Oberflächen mit Temperatur bis zu 150 °C oder 260
        °C zu beschichten. Die Temperaturbeständigkeit ohne Schichtverfärbung bis 650 °C
      </li>
      <li>Systeme mit hoher chemischer Beständigkeit - Beständigkeit gegen aggressive Chemikalien, Erdölprodukte,
        Strahlung
      </li>
      <li>Isoliersysteme – Temperaturbeständigkeit ohne Oberflächenverfärbung bis 180 °C gemäß PN-ISO 13732-1</li>
    </ul></li>
  </ul>
  <p className="text-center">Während des Gesamtprozesses werden die Messungen und Überwachung gemäß PN-EN ISO1090-2,
    PN-EN ISO8501-1, PN-EN ISO12944-5:2009, bzw. gemäß von Kunden gewünschtem Standard durchgeführt.</p>
</>

export default PaintingDE