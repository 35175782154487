import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import "../scss/main.scss"
import bannerImage from "../../static/images/banner/lakiernictwo.jpg"
import ServicePage from "../layouts/ServicesPage"
import PaintingContent from "../content/Painting"
export default function MeltingPage() {
  const intl = useIntl()
  return <ServicePage bannerImage={bannerImage}>
    <PaintingContent lang={intl.locale} />
  </ServicePage>
}
