import React from "react"
import classNames from 'classnames';
import styles from "./styles.module.scss"
import img1 from "../../../static/images/gallery_1/img1.jpg"
import img2 from "../../../static/images/gallery_1/img2.jpg"
import img3 from "../../../static/images/gallery_1/img3.jpg"
import img4 from "../../../static/images/gallery_1/img4.jpg"
import img5 from "../../../static/images/gallery_1/img5.jpg"
import img6 from "../../../static/images/gallery_1/img6.jpg"
import img7 from "../../../static/images/gallery_1/img7.jpg"

const GALLERY = [img2, img5, img6, img7, img3, img4, img1]

const PaintingPL = () => <>
  <h2>Lakiernictwo</h2>
  <p className="bigger-text text-center">
    Oferujemy nowoczesne systemy nakładania powłok ochronnych i antykorozyjnych: od lekkich
    systemów przemysłowych (cienka warstwa farby), nadających się do maszyn i stali, używanych w
    łagodnych warunkach, po systemy o wysokiej wydajności (gruba warstwa farby), używane w
    trudnych warunkach chemicznych i środowiskowych. Nasze usługi wykonujemy przy użyciu farb i
    lakierów produkowanych przez producentów z całego świata dostępnych na rynku europejskim.</p>
  <p>Prace wykonywane w naszej lakierni obejmują:</p>
  <ul>
    <li>Nakładanie antykorozyjnych systemów jedno i wielowarstwowych,</li>
    <li>Nakładanie systemów ognioochronnych na konstrukcjach stalowych typu R15,
      R30, R60, R120 (posiadamy certyfikaty to projektowania, nadzoru i nakładania
      farb ognioochronnych m.in. International Coatings, PPG Coatings, Carboline,
      Charflame, Sika, i inne)
    </li>
    <li>Przygotowanie podłoża i aplikacja zgodnie z wytycznymi PN-EN ISO 1090-2, PN-
      EN ISO 8501-1, PN-EN ISO 12944-5:2009, oraz Zakładowym Systemem Kontroli
      Jakości)
    </li>
    <li>Nakładanie specjalistycznych powłok ochronnych:
      <ul>
        <li>Termoodpornych – możliwość nakładania farb na podłoża o temp do 150 lub 260
          °C, odporność termiczna bez przebarwień powłoki do 650 °C
        </li>
        <li>Chemoodpornych – m.in. systemy z gwarancją dla zbiorników na „wodę
          demineralizowaną”, działanie agresywnych związków chemicznych,
          produktów ropopochodnych, promieniowania itp.
        </li>
        <li>Izolacyjnych – odporność termiczna bez przebarwień powłoki do 180 °C spełnia
          wymagania normy bezpieczeństwa PN-ISO 13732-1 (bezpieczne w dotyku 55°C,
          gdy konstrukcja ma temp do 180 °C)
        </li>
      </ul></li>
  </ul>
  <p className="text-center"> W trakcie aplikacji zabezpieczenia antykorozyjnego wykonujemy pomiary oraz monitoring
    parametrów środowiskowych, przygotowania podłoża i aplikacji własnym sprzętem, zgodnie z normą PN-EN ISO1090-2,
    PN-EN ISO8501-1, PN-EN ISO12944-5:2009 lub według innych standardów, jeśli takie zostały uzgodnione z
    Klientem.</p>

  <div className={classNames(styles.Gallery, "row")}>
    {GALLERY.map(item => <div className={classNames("col-md-4", styles.galleryItem)}><img src={item} alt={"image"} /></div>)}
  </div>
</>

export default PaintingPL
