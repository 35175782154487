import React from "react"
import PaintingPL from "./painting.pl"
import PaintingEN from "./painting.en"
import PaintingDE from "./painting.de"

const PaintingContent = ({ lang }) => {
  if (lang === "de") {
    return <PaintingDE />
  } else if (lang === "en") {
    return <PaintingEN />
  }
  return <PaintingPL />
}

export default PaintingContent